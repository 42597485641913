/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import PropTypes from "prop-types"

export const FormFieldset = (props) => (
  <Box
    as="fieldset"
    sx={{
      padding: 0,
      borderWidth: 0,
    }}
    {...props}
  />
)

FormFieldset.propTypes = {
  children: PropTypes.node,
}
