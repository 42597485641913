/** @jsx jsx */
import React, { useRef, useEffect } from "react"
import { jsx, Flex, Box, Label, Input, Textarea } from "theme-ui"

import usePrefersReducedMotion from "src/hooks/usePrefersReducedMotion"
import Section from "src/components/Section"
import Container from "src/components/Container"
import VisuallyHidden from "src/components/VisuallyHidden"
import HStack from "src/components/HStack"
import Stack from "src/components/Stack"

import { REDUCER_ACTIONS, REGEX } from "./constants"

import useForm from "./useForm"
import { submitEnquiryFormAPI } from "./api"
import { smoothScrollToRef } from "./helpers"
import {
  FormFieldset,
  FormAlert,
  FormAlertFail,
  FormRadio,
  FormRadioLabel,
  FormButton,
  FormLegend,
  FormButtonSubmit,
  FormDebugBox,
} from "./components"

//ENQUIRY FORM CONSTANTS

const FORM_FIELDS = {
  wasItHelpful: process.env.GATSBY_ENQUIRY_FORM_FIELD_ID_1,
  email: process.env.GATSBY_ENQUIRY_FORM_FIELD_ID_2,
  comment: process.env.GATSBY_ENQUIRY_FORM_FIELD_ID_3,
  pageUrl: process.env.GATSBY_ENQUIRY_FORM_FIELD_ID_4,
}

const EnquiryForm = ({ location }) => {
  // pass {debug: true} to useForm to enable debug mode
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    state,
    dispatch
  } = useForm()

  const formRef = useRef(null)
  const prefersReducedMotion = usePrefersReducedMotion()

  // Watch whether 'section one' is complete
  const watchSectionOneComplete = watch(FORM_FIELDS.wasItHelpful, false)
  useEffect(() => {
    if(watchSectionOneComplete) {
      dispatch({ type: REDUCER_ACTIONS.START })
    }
  }, [watchSectionOneComplete])

  const handleOnSubmit = async (data, event) => {
    event.preventDefault()
    dispatch({ type: REDUCER_ACTIONS.SUBMITTING })
    await submitEnquiryFormAPI(data)
      .then((response) => {
        if (response.is_valid) {
          dispatch({ type: REDUCER_ACTIONS.THANK_YOU, payload: response.confirmation_message })
          // Reset form on success
          reset();
          smoothScrollToRef(formRef, prefersReducedMotion);
        } else {
          // Validation test on server side
          if(response.validation_messages) {
            dispatch({ type: REDUCER_ACTIONS.SUBMIT_ERROR, payload: response.validation_messages })
          }
        }
      })
      .catch((error) => {
        dispatch({ type: REDUCER_ACTIONS.SUBMIT_ERROR, payload: error.message })
      })
  }

  const handleOnCancel = (event) => {
    reset()
    dispatch({ type: REDUCER_ACTIONS.INIT })
  }

  return (
    <Section>
      <Container>
        <Box
          as="form"
          id="enquiryForm"
          onSubmit={handleSubmit(handleOnSubmit)}
          ref={formRef}
        >
          <Stack space={6}>
            <FormFieldset id="sectionOne">
              <FormLegend>Was this page helpful?</FormLegend>
              <Flex>
                <VisuallyHidden>Please select Yes or No and the second form section will appear below:</VisuallyHidden>
                <HStack space={3}>
                  <FormRadioLabel>
                    <FormRadio
                      {...register(FORM_FIELDS.wasItHelpful)}
                      value="Yes"
                    />
                    Yes
                  </FormRadioLabel>
                  <FormRadioLabel>
                    <FormRadio
                      {...register(FORM_FIELDS.wasItHelpful)}
                      value="No"
                    />
                    No
                  </FormRadioLabel>
                </HStack>
              </Flex>
            </FormFieldset>

            {watchSectionOneComplete && (
              <FormFieldset id="sectionTwo">
                <Stack space={6}>
                  <Box>
                    <Label htmlFor={FORM_FIELDS.email} mb={2}>
                      Tell me your email <span sx={{ color: "red" }}>*</span>
                    </Label>
                    <Box>
                      <Input
                        {...register(FORM_FIELDS.email, {
                          required: true,
                          pattern: REGEX.EMAIL,
                        })}
                        id={FORM_FIELDS.email}
                        type="text"
                        autoComplete="off"
                      />
                      {errors[FORM_FIELDS.email]?.type === "pattern" && (
                        <FormAlertFail>
                          Please enter valid email address
                        </FormAlertFail>
                      )}
                      {errors[FORM_FIELDS.email]?.type === "required" && (
                        <FormAlertFail>Email address is required</FormAlertFail>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Label htmlFor={FORM_FIELDS.comment} mb={2}>
                      <Box as="span">Please add your comments:</Box>
                    </Label>
                    <Box>
                      <Textarea
                        {...register(FORM_FIELDS.comment)}
                        id={FORM_FIELDS.comment}
                        maxLength="5000"
                        rows={4}
                        mb={4}
                      />
                    </Box>
                    {errors[FORM_FIELDS.comment]?.type === "required" && (
                      <FormAlertFail>Comment is required</FormAlertFail>
                    )}
                  </Box>
                  <Input
                    {...register(FORM_FIELDS.pageUrl, {
                      value: new URL(
                        window?.location
                          ? window.location.pathname +
                            window.location.search +
                            window.location.hash
                          : location.pathname + location.search + location.hash,
                        process.env.GATSBY_BASE_URL
                      ),
                    })}
                    id={FORM_FIELDS.pageUrl}
                    type="hidden"
                  />
                  <Box sx={{ maxWidth: "500px" }}>
                    <Flex>
                      <FormButtonSubmit isSubmitting={state.isSubmitting} />
                      <FormButton onClick={handleOnCancel} ml={16}>
                        Cancel
                      </FormButton>
                    </Flex>
                  </Box>
                </Stack>
              </FormFieldset>
            )}
          </Stack>
          {/** trigger rerender by changing key **/}
          <FormAlert key={state.messageType} type={state.messageType}>
            <span dangerouslySetInnerHTML={{__html: state.message}} />
          </FormAlert>
          {state.debug && <FormDebugBox>{state.debug}</FormDebugBox>}
        </Box>
      </Container>
    </Section>
  )
}

export default EnquiryForm

EnquiryForm.propTypes = {}
