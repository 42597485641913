/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import PropTypes from "prop-types"

import { MESSAGE_TYPES } from "../constants"

// Alert
export const FormAlert = ({ type, ...props }) => {
  let theme = "red"
  switch (type) {
    case MESSAGE_TYPES.SUCCESS:
      theme = "black"
      break
    case MESSAGE_TYPES.FAIL:
      theme = "red"
      break
    case MESSAGE_TYPES.INFO:
    default:
      theme = "black"
      break
  }

  // role alert to trigger screen reader
  return <Box mt={2} sx={{ color: theme }} role="alert" {...props} />
}

FormAlert.propTypes = {
  type: PropTypes.oneOf(Object.values(MESSAGE_TYPES)),
  children: PropTypes.node,
}

export const FormAlertFail = (props) => (
  <FormAlert type={MESSAGE_TYPES.FAIL} {...props} />
)

FormAlertFail.propTypes = {
  children: PropTypes.node,
}
