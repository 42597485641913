/** @jsx jsx */
import React, { useReducer, useEffect } from "react"
import { useForm as rhfUseForm } from "react-hook-form"

import { MESSAGE_TYPES, REDUCER_ACTIONS } from "./constants"

/**
 * Custom RHF useForm with reducer
 * @param {object} options
 * @param {boolean} options.debug
 * @returns {object} useForm
 */

const useForm = (options = { debug: false }) => {
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    watch,
    reset,
  } = rhfUseForm()

  const [state, dispatch] = useReducer(formReducer, {
    ...(options.debug && { debug: "Debug mode on" }),
    ...initialState,
  })

  // Detect RHF Validation errors
  // Ref: https://github.com/react-hook-form/react-hook-form/issues/1574#issuecomment-806898470
  useEffect(() => {
    if (
      Object.keys(errors).length > 0 &&
      formState.isSubmitted &&
      !formState.isValid
    ) {
      dispatch({ type: REDUCER_ACTIONS.VALIDATION_ERROR, payload: errors })
    }
  }, [formState.submitCount])

  return {
    register,
    handleSubmit,
    // formState, // Not needed for now
    errors,
    watch,
    reset,
    state,
    dispatch,
  }
}

const initialState = {
  isSubmitting: false,
  messageType: MESSAGE_TYPES.INFO,
  message: "",
}

const formReducer = (state, action) => {
  switch (action.type) {
    case REDUCER_ACTIONS.INIT: {
      return {
        ...state,
        ...initialState,
        ...(state.debug && { debug: "Debug mode on" }),
      }
    }
    case REDUCER_ACTIONS.START: {
      return {
        ...state,
        isSubmitting: false,
        messageType: MESSAGE_TYPES.INFO,
        message: "",
        ...(state.debug && { debug: "Start Triggered" }),
      }
    }
    case REDUCER_ACTIONS.SUBMITTING: {
      return {
        ...state,
        isSubmitting: true,
        messageType: MESSAGE_TYPES.INFO,
        message: "Submitting",
        ...(state.debug && { debug: "Submitting Triggered" }),
      }
    }
    case REDUCER_ACTIONS.VALIDATION_ERROR: {
      return {
        ...state,
        isSubmitting: false,
        messageType: MESSAGE_TYPES.INFO,
        message: "",
        ...(state.debug && {
          debug: `Validation Error triggered. Found in: ${JSON.stringify(
            Object.keys(action.payload)
          )}`,
        }),
      }
    }
    case REDUCER_ACTIONS.THANK_YOU: {
      return {
        ...state,
        isSubmitting: false,
        messageType: MESSAGE_TYPES.SUCCESS,
        // confirmation message from WP Gravity forms
        message: action.payload,
        ...(state.debug && { debug: "Thank You Triggered" }),
      }
    }
    case REDUCER_ACTIONS.SUBMIT_ERROR: {
      console.log(`Error Message: ${action.payload}`)
      return {
        ...state,
        isSubmitting: false,
        messageType: MESSAGE_TYPES.FAIL,
        message: `Error: Something went wrong. Please try again later.`,
        ...(state.debug && {
          debug: `Submit Error Triggered: ${action.payload}`,
        }),
      }
    }
    default: {
      console.error("Unknown action type");
      return state
    }
  }
}

export default useForm
