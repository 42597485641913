/** @jsx jsx */
import { jsx, Box, Input, Button, Spinner } from "theme-ui"
import PropTypes from "prop-types"

import { focusOutlineTight } from "src/theme"

// Buttons
const buttonSx = {
  color: "inherit",
  padding: "14px 24px",
  border: "none",
  font: "inherit",
  appearance: "none",
  cursor: "pointer",
  borderRadius: "4px",
  marginBottom: "20px",
  fontWeight: "700",
  fontSize: "18px",
  display: "inline-block",
  ...focusOutlineTight,
  "&:hover, &:active": {
    opacity: "0.8",
  },
  "&:disabled": {
    opacity: 1,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
}

export const FormButton = (props) => (
  <Button
    sx={{
      ...buttonSx,
      color: "var(--theme-ui-colors-black)",
      backgroundColor: "var(--theme-ui-colors-white)",
    }}
    type="button"
    {...props}
  />
)

FormButton.propTypes = {
  children: PropTypes.node,
}

export const FormButtonSubmit = ({ isSubmitting, ...props }) => {
  const sx = {
    ...buttonSx,
    color: "var(--theme-ui-colors-white)",
    backgroundColor: "var(--theme-ui-colors-blue)",
    width: "200px",
  }

  if (isSubmitting) {
    return (
      <Box
        sx={{
          ...sx,
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner title="Submitting" size={24} sx={{ color: "white" }} />
      </Box>
    )
  }

  return <Input sx={sx} type="submit" value="Submit" {...props} />
}

FormButtonSubmit.propTypes = {
  isSubmitting: PropTypes.bool,
}
