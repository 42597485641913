/** @jsx jsx */
import { jsx } from "theme-ui"

const Stack = (props) => {
  const { as: TagName = "div", space, ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        display: "flex",
        gap: space,
        flexDirection: "column",
      }}
    />
  )
}

export default Stack
