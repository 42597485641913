/** @jsx jsx */
import { jsx } from "theme-ui"

const Section = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        py: "1px",
        my: "-1px",
      }}
    />
  )
}

export default Section
