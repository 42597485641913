/** @jsx jsx */
import React from "react"
import { jsx, Radio, Label } from "theme-ui"
import PropTypes from "prop-types"

// Style: Remove Theme UI focus - bg highlight
// https://github.com/system-ui/theme-ui/blob/10b33dee2f188e440600e02a4f2bf32d402588d0/packages/components/src/Radio.tsx#L89
export const FormRadio = React.forwardRef(
  ({ onChange, onBlur, name, value }, ref) => (
    <Radio
      type="radio"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      ref={ref}
      sx={{
        "input:focus ~ &": { bg: "transparent" },
        "input:focus-visible ~ &": { fill: "yellow" },
        "cursor": "pointer",
      }}
    />
  )
)

FormRadio.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
}

export const FormRadioLabel = (props) => (
  <Label sx={{cursor: "pointer"}} {...props} />
);

FormRadioLabel.propTypes = {
  children: PropTypes.node,
}
