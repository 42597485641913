/**
 * Smooth Scroll
 **/

export const smoothScrollToRef = (ref, prefersReducedMotion = false) => {
  // Checks if the user prefers reduced motion and if so, skips the smooth scroll.
  const scrollBehavior = prefersReducedMotion ? "auto" : "smooth"
  if (ref.current !== null) {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: scrollBehavior })
    }, 500)
  }
}
