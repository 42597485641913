/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import PropTypes from "prop-types"

export const FormLegend = (props) => (
  <Box as="legend" mb={2} sx={{ fontSize: 3, fontWeight: "bold" }} {...props} />
)

FormLegend.propTypes = {
  children: PropTypes.node,
}
