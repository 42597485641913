// form states
export const REDUCER_ACTIONS = {
  INIT: "default",
  START: "start",
  SUBMITTING: "submitting",
  THANK_YOU: "thankYou",
  VALIDATION_ERROR: "validationError",
  SUBMIT_ERROR: "submitError",
}

// used to style alert messages
export const MESSAGE_TYPES = {
  SUCCESS: "success",
  FAIL: "fail",
  INFO: "info",
}

// regex used in form fields
export const REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
}
