/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import PropTypes from "prop-types"

// Debug Box
export const FormDebugBox = ({ children, ...rest }) => (
  <Box
    mt={20}
    mb={2}
    sx={{ display: "flex", flexDirection: "column", maxWidth: 700 }}
    {...rest}
  >
    <Box as="span" sx={{ padding: 2, bg: "red", color: "white" }}>
      Debug Mode on:
    </Box>{" "}
    <Box sx={{ padding: 2, bg: "#f0c3ba", color: "black", flex: 1 }}>
      {children}
    </Box>
  </Box>
)

FormDebugBox.propTypes = {
  children: PropTypes.node,
}
